// Footer.js
import React from "react";
import { Box, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom"; // Importer Link depuis react-router-dom

function Footer() {
  return (
    <Box
      sx={{
        padding: "16px",
        backgroundColor: "#000",
        textAlign: "center",
        position: "fixed",
        bottom: 0,
        width: "100%",
        borderTop: "1px solid #e0e0e0",
      }}
    >
      <Typography variant="body1" color="#fff">
        &copy; {new Date().getFullYear()} Cryptofanz.eu
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", gap: "16px" }}>
        <RouterLink
          to="/contact"
          style={{
            textDecoration: "underline",
            textDecorationColor: "#fff",
            color: "#fff",
            marginLeft: "8px",
          }}
        >
          Contact
        </RouterLink>
        <RouterLink
          to="/terms"
          style={{
            textDecoration: "underline",
            textDecorationColor: "#fff",
            color: "#fff",
          }}
        >
          Mentions légales
        </RouterLink>
      </Box>
    </Box>
  );
}

export default Footer;
