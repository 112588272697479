import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { IoIosMail } from "react-icons/io";

const ContactPage = () => {
  useEffect(() => {
    document.title = "Cryptofanz.eu - Contactez-nous";
  }, []);

  const onVerify = (token) => {
    alert("hCaptcha token", token);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("a.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
      });

      const result = await response.text();
      if (result.trim() === "ok") {
        alert("Votre message a été envoyé!");
        setFormData({ name: "", email: "", subject: "", message: "" });
      } else if (result.trim() === "!ok") {
        alert("Une erreur s'est produite, veuillez réessayer.");
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <script src="https://js.hcaptcha.com/1/api.js" async defer></script>
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 4 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            fontFamily: "'Libre Baskerville Bold', serif",
            color: "#FF0000",
          }}
        >
          Contactez-nous
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            mt: 3,
            width: "100%", // Prendre toute la largeur
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "center", width: "80%" }}
          >
            {/* Forcer la grille à 100% */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: "3px",
                      borderStyle: "solid",
                      borderColor: "#ff1448",
                    },
                  },
                }}
                label="Nom"
                name="name"
                variant="outlined"
                required
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: "3px",
                      borderStyle: "solid",
                      borderColor: "#000",
                    },
                  },
                }}
                label="Email"
                name="email"
                type="email"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: "3px",
                      borderStyle: "solid",
                      borderColor: "#808080",
                    },
                  },
                }}
                label="Message"
                name="message"
                multiline
                rows={3}
                required
                value={formData.message}
                onChange={handleChange}
              />
            </Grid>
            {/* Intégration du hCaptcha */}
            <Grid item xs={12}>
              <form>
                <HCaptcha sitekey="2eb45b32-ee72-4486-8db0-6d0c763c1030" onVerify={onVerify} />
              </form>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#2128f5" }}
              >
                Envoyer
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 2 }}>
          <IconButton
            component="a"
            href="mailto:webmaster@cryptofanz.eu"
            target="_blank"
            aria-label="Mail"
          >
            <IoIosMail fontSize="32" color="#0052ff" />
          </IconButton>
        </Box>
      </Container>
    </>
  );
};

export default ContactPage;
