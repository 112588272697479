import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Button,
  Link,
} from "@mui/material";

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  useEffect(() => {
    document.title =
      "Cryptofanz.eu - " + (isLogin ? "Connexion" : "Inscription");
  }, [isLogin]);

  const handleToggle = () => {
    setIsLogin(!isLogin);
  };

  const [selectedValue, setSelectedValue] = useState("fan");

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirm: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      ...formData,
      role: selectedValue,
    };

    const url = isLogin ? "/api/login" : "/api/register"; // Dynamically set URL

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        //body: new URLSearchParams(data).toString(),
        //});
        //...(isLogin ? {} : { body: JSON.stringify(data) }),
        body: JSON.stringify(data),
      });

      const result = await response.text();
      alert(result);
      if (result.trim() === "ok") {
        alert("Votre message a été envoyé!");
        setSelectedValue("fan");
        setFormData({ name: "", email: "", subject: "", message: "" });
        //navigate("/");
      } else if (result.trim() === "!ok") {
        alert("Une erreur s'est produite, veuillez réessayer.");
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 4 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            fontFamily: "'Libre Baskerville Bold', serif",
          }}
        >
          Auth
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            mt: 3,
            width: "100%", // Prendre toute la largeur
          }}
        >
          {/* Ajout des boutons radio avant le premier TextField */}
          <RadioGroup row value={selectedValue} onChange={handleRadioChange}>
            <FormControlLabel value="fan" control={<Radio />} label="Fan" />
            <FormControlLabel
              value="créateur"
              control={<Radio />}
              label="Créateur"
            />
          </RadioGroup>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "center", width: "80%" }}
          >
            {/* Forcer la grille à 100% */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: "3px",
                      borderStyle: "solid",
                      borderColor: "#ff1448",
                    },
                  },
                }}
                label="Email"
                name="email"
                variant="outlined"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: "3px",
                      borderStyle: "solid",
                      borderColor: "#000",
                    },
                  },
                }}
                label="Mot de passe"
                name="password"
                //type=""
                required
                value={formData.password}
                onChange={handleChange}
              />
            </Grid>
            {!isLogin && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderWidth: "3px",
                        borderStyle: "solid",
                        borderColor: "#808080",
                      },
                    },
                  }}
                  label="Confirmer le mot de passe"
                  name="confirm"
                  variant="outlined"
                  required
                  value={formData.confirm}
                  onChange={handleChange}
                />
              </Grid>
            )}
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#2128f5" }}
              >
                {isLogin ? "Se connecter" : "S'inscrire"}
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Link href="#" variant="body2" onClick={handleToggle}>
                {isLogin
                  ? "Vous n'avez pas de compte ? Inscrivez-vous"
                  : "Vous avez déjà un compte ? Connectez-vous"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Auth;
