// Header.js
import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu"; // Assurez-vous d'avoir installé les icônes MUI
import Box from "@mui/material/Box";
import { Link as RouterLink } from "react-router-dom"; // Importer Link depuis react-router-dom
import "./fonts.css";

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="relative"
      sx={{
        backgroundColor: "#000",
        boxShadow: "none",
        top: 0,
        width: "100%",
      }}
    >
      <Toolbar
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>
            Création de site internet
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            Dév, sonsultant SI Forfait jour
          </MenuItem>
        </Menu>

        <RouterLink
          to="/"
          style={{
            textDecoration: "none",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "'Big Shoulders Display', sans-serif !important",
              color: "#FF0099 !important",
              textShadow: `
              0 0 5px #FF0099 !important,
              0 0 10px #FF0099 !important,
              0 0 20px #FF0099 !important,
              0 0 80px #FF0099 !important,
              0 0 90px #FF0099 !important,
              0 0 100px #FF0099 !important,
              0 0 150px #FF0099 !important
              `,
            }}
          >
            Crypto
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "'Big Shoulders Display', sans-serif !important",
              color: "#F6A302 !important",
              textShadow: `
              0 0 5px #F6A302 !important,
              0 0 10px #F6A302 !important,
              0 0 15px #F6A302 !important,
              0 0 20px #F6A302 !important,
              0 0 25px #F6A302 !important,
              0 0 30px #F6A302 !important,
              0 0 35px #F6A302 !important
            `,
            }}
          >
            Fanz
          </Typography>
        </RouterLink>
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            margin: 0,
            padding: 0,
          }}
        ></div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
